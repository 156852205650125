import React, { useState, useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import queryString from 'query-string'
import SEO from "../components/seo"
import Form from '../components/hoc/Form/Form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import resetPasswordEmail from '../state/async/authResetPasswordEmail';
import {formError, getErrors, emailPasswordSend, emailPasswordSending} from '../state/reducers/auth';

const ResetPassword = ({location, emailPasswordSend, emailPasswordSending, resetEmailAction, formError}) => {
  const i18n = useIntl().formatMessage
  
  // const [token, setToken] = useState('')
  // const [submitted, setSubmitted] = useState(false)
  // const [submitting, setSubmitting] = useState(false)

  // useEffect(() => {
  //   setToken(queryString.parse(location.search)['token'])
  //   }, [])


  const submitHandler = (sub) => {
    resetEmailAction(sub.email)
  }

  return (
    <>
      <SEO title="Reset password" />
      <section className="section-padding-big">
        <div className="container">
          <div className="row center-xs">
            <div className="col col-xs-12 col-md-6">
              <div className="card">
                <h1 className="h3">Reset Password</h1>
                {emailPasswordSend ?
                <p>Vi har sendt dig en mail med et link, hvor du kan lave et nyt password.</p>
                : 
                <Form 
                  loading={emailPasswordSending}
                  success={emailPasswordSend}
                  submitHandler={submitHandler}
                  submitText={ `${i18n({ id: "general.send" })}  →`}
                  formElements={[
                    { type: 'email', name: 'email', label: 'Email', value: '', required: true },
                  ]}
                  formError={formError}
                />
                }
                
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
    )
}


const mapStateToProps = state => ({
  error: getErrors(state.auth),
  emailPasswordSend: emailPasswordSend(state.auth),
  emailPasswordSending: emailPasswordSending(state.auth),
  formError: formError(state.auth)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  resetEmailAction: resetPasswordEmail,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
